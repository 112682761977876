@font-face {
  src: url('Brown-Regular.otf');
  font-family: "Brown";
}

@font-face {
  src: url('MuseoSans_500.otf');
  font-family: "Museo Sans";
}

@font-face {
  src: url('SharpSans-medium.otf');
  font-family: "Sharp Sans";
}

@font-face {
  src: url('samsungsharpsans-bold.woff');
  font-family: "Sharp Sans Bold";
  font-weight: bold;
}

@font-face {
  src: url('samsungsharpsans-medium.woff');
  font-family: "Sharp Sans Medium";
  font-weight: bold;
}

@font-face {
  src: url('SharpSans-semibold.otf');
  font-family: "Sharp Sans Semibold";
}

@font-face {
  src: url('Circular-Font.otf');
  font-family: "Circular Std";
}

@font-face {
  src: url('AvenirLTStd-Medium-600.otf');
  font-family: "Avenir";
}

@font-face {
  src: url('FontsFree-Net-SFProDisplay-Regular.ttf');
  font-family: "SF Pro Display";
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

.bg-dark {
  background: #171e40 !important;
}

/* Hero Section */

.hero_title {
  font-family: Brown;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 118.5%;
  letter-spacing: -0.02em;
  color: #171e40;
}

.hero_text {
  font-family: Sharp Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 179%;
  letter-spacing: 0.01em;
  color: #171e40;
}

/* // Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
  .sm_hide {
    display: none;
  }

  .custom_margin_top {
    margin-top: 2rem;
  }

  .header {
    background-image: none;
  }
}

/* // Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) and (max-width: 767.98px) {
  .custom_margin_top {
    margin-top: 2rem;
  }
}

/* // Medium devices (tablets, 768px and up) */

@media (min-width: 768px) and (max-width: 991.98px) {
  .hero_title {
    margin-top: 1rem;
  }
}

/* // Large devices (desktops, 992px and up) */

@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
}
